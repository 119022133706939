export const isDevEnvironment = (): boolean => process.env.NODE_ENV === 'development'
export const isDevelopmentEnvironmentServed = (): boolean =>
  process.env.ENVIRONMENT === 'development'
export const toggleMetricsEnabled = (): boolean =>
  process.env.TOGGLE_METRICS_ENABLED === 'true'
export const toggleSuccessFailureMetricsEnabled = () =>
  process.env.TOGGLE_SUCCESS_FAILURE_METRICS_ENABLED === 'true'
export const toggleWebsocketMetricsEnabled = () =>
  process.env.TOGGLE_WEBSOCKET_METRICS_ENABLED === 'true'
export const toggleNonArchivalUrlEnabled = (): boolean =>
  process.env.TOGGLE_NON_ARCHIVAL_URL_ENABLED === 'true'
export const toggleWssEnabledForEtherProd = (): boolean =>
  process.env.TOGGLE_WSS_ENABLED_FOR_ETHER_PROD === 'true'
export const toggleMultiRegionEnabled = (): boolean =>
  process.env.TOGGLE_MULTIREGION_ENABLED === 'true'
